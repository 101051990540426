import React, { Fragment } from "react";
import img from "../assets/img/dragon.png";
import "../assets/css/custom.css";

const Header = () => {
  return (
    <Fragment>
      <div className="Header">
        <nav className="navbar navbar-expand-lg bg-dark">
          <div className="container">
            <a className="navbar-brand" href="#">
              Dragon Paint Games™
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="navbar-toggler-icon" />
              <span className="navbar-toggler-icon" />
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" href="#" hidden>
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#" hidden>
                    Projects
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#" hidden>
                    About Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <img src={img} alt="dragon" width="367px" height="282px" />
      </div>
    </Fragment>
  );
};

export default Header;
