import React, { Fragment } from "react";
import Header from "./Components/Header";
import Projects from "./Components/Projects";

function App() {
  return (
    <Fragment>
      <Header />
      <Projects />
    </Fragment>
  );
}

export default App;
