import React, { Fragment, Component } from "react";
import PuffPuffGiff from "../assets/gifs/Brownie.gif";

export default class Projects extends Component {
  render() {
    let projectsList = [
      {
        id: 1,
        pname: "Puff Puff the Magic Pipe",
        description:
          "Puff Puff The Magic Pipe is the world's first 420-themed 3D platformer featuring a magical pipe that came to life to save his Best Buds!",
        link:
          "https://store.steampowered.com/app/1477250/Puff_Puff_The_Magic_Pipe/",
        gif: PuffPuffGiff,
        mobile: "https://play.google.com/store/apps/details?id=com.DragonPaintGames.PuffPuffTheMagicPipe"
      },
    ];

    const project = projectsList.map((row) => {
      return (
        <div className="col-md-4 m-auto p-10">
          <div className="card card-pricing bg-dark">
            <div className="card-body">
              <div className="icon">
                <i className="material-icons">
                  <img src={row.gif} width="100%" height="100%" alt="Gif"/>
                </i>
              </div>
              <h3 className="card-title">{row.pname}</h3>
              <p className="card-description">{row.description}</p>
              <a
                href={row.link}
                target="_blank"
                className="btn btn-primary btn-round"
              >
                Steam | PC
              </a>
              <a
                href={row.mobile}
                target="_blank"
                className="btn btn-danger btn-round"
              >
                Android
              </a>
            </div>
          </div>
        </div>
      );
    });

    return (
      <Fragment>
        <div className="row">{project}</div>
      </Fragment>
    );
  }
}
